import React from 'react'
import styled, { x, breakpoints, css } from '@xstyled/styled-components'

import Tick from '@assets/svg/tick.svg'

const ListItemsTick = ({ list }, i) => {
  return (
    <>
      {list?.map && (
        <ul key={i}>
          {list?.map((item, i) => (
            <S.ListItem as="li" key={i}>
              <S.TickWrapper>
                <Tick />
              </S.TickWrapper>

              <span>{item}</span>
            </S.ListItem>
          ))}
        </ul>
      )}
    </>
  )
}

export default ListItemsTick

const S = {}

S.TickWrapper = styled.box`
  position: absolute;
  content: '';
  left: 0;
  display: block;
  width: 24px;
  ${breakpoints({
    xs: css`
      height: 1.5rem;
    `,
    md: css`
      height: 24px;
    `,
  })}

  path {
    fill: currentColor;
  }
`

S.ListItem = styled.box`
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  ${breakpoints({
    xs: css`
      font-size: 15px;
    `,
    md: css`
      font-size: 17px;
    `,
  })}
`
