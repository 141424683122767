import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { variant } from '@utils/variant'
import { breakpoints, margin, th, space } from '@xstyled/system'
import { Link } from 'gatsby'

import arrowLeft from '@assets/svg/arrow-left.svg'

const BackButton = ({ to, linkButton, variant: _variant }) => {
  return (
    <S.Back to={to} variant={_variant}>
      <S.CircleWrapper>
        <S.BackIcon />
      </S.CircleWrapper>
      {linkButton}
    </S.Back>
  )
}

const S = {}

S.Back = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: ${th('fontWeights.bold')};
  align-items: center;
  cursor: pointer;

  ${breakpoints({
    xs: css`
      margin-bottom: 40;
      width: 100%;
      font-size: 17;
      line-height: 1;
    `,
    md: css`
      margin-bottom: 40;
      margin-top: 20;
      width: 200;
      font-size: 17;
    `,
    lg: css`
      margin-bottom: 0;
    `,
  })}

  ${variant({
    default: 'light',
    variants: {
      dark: css`
        color: ${th('colors.light')};
        --svg-path-color: ${th('colors.light')};
        &:hover {
          --svg-path-color: ${th('colors.light')};
          --hover-grey: rgba(255, 255, 255, 0.2);
          color: ${th('colors.light')};
        }
      `,
      light: css`
        --svg-path-color: ${th('colors.dark')};
        color: ${th('colors.dark')};
        &:hover {
           --hover-grey: rgba(0, 0, 0, 0.2);
          color: ${th('colors.dark')};
        },
      `,
    },
  })}
`

S.CircleWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  background: var(--hover-grey, transparent);
  margin-right: 10;
`

S.BackIcon = styled(arrowLeft)`
  ${breakpoints({
    xs: css`
      width: 30;
      height: 24;
    `,
    md: css`
      width: 27;
      height: 16;
    `,
  })}

  path {
    fill: var(--svg-path-color);
  }
`

export default BackButton
