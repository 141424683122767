import React from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { Container } from '@atoms/Grid'
import ImageContent from '@molecules/ImageContent'

const CopyImage = ({ data }) => {
  return (
    <S.Section>
      <ImageContent data={data} inverse={data.inverse} />
    </S.Section>
  )
}

export default CopyImage

const S = {}

S.Section = styled(Container)`
  ${breakpoints({
    xs: css`
      padding-bottom: 100;
    `,
    md: css`
      padding-top: 70;
      padding-bottom: 70;
    `,
    lg: css`
      padding-top: 80;
      padding-bottom: 80;
    `,
  })}
`
