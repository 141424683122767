import React from 'react'
import styled, { x, breakpoints, css, th } from '@xstyled/styled-components'

import { GatsbyImage } from 'gatsby-plugin-image'
import { H2, H4 } from '@atoms/Typography'
import { Container } from '@atoms/Grid'

import { ButtonLink } from '@atoms/Button'
import ListItemsTick from '@atoms/ListItemsTick'

const Cta = ({ data }) => {
  const {
    title,
    subtitle,
    button,
    buttonLink,
    color = 'grey',
    list,
    image,
  } = data
  return (
    <S.Container>
      <S.ColoredCard color={color}>
        <x.div>
          <H4 as="p" mb={{ xs: 15, md: 20 }}>
            {subtitle}
          </H4>
          <H2
            as="p"
            fontSize={{ xs: 28, md: 32 }}
            mb={{ xs: 30, md: 50 }}
            maxWidth="400px"
            lineHeight="1"
          >
            {title}
          </H2>
          <x.div mb={{ xs: 30 }}>
            <S.ButtonLink
              variant={color === 'dark' ? 'light' : 'dark'}
              to={buttonLink}
              arrowRight
            >
              {button}
            </S.ButtonLink>
          </x.div>
          <ListItemsTick list={list} />
          {image && (
            <S.Image>
              <GatsbyImage
                alt={title}
                width="100%"
                height={505}
                aspectRatio={1.2283464567}
                image={image.childImageSharp.gatsbyImageData}
              />
            </S.Image>
          )}
        </x.div>
      </S.ColoredCard>
    </S.Container>
  )
}

export default Cta

const S = {}

S.ColoredCard = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${({ color }) => th(`colors.${color}`)};
  color: ${({ color }) =>
    color === 'dark' ? th('colors.light') : th('colors.dark')};

  ${breakpoints({
    xs: css`
      border-radius: 5;
      padding: 40;
    `,
    xl: css`
      border-radius: 10;
      padding-top: 90;
      padding-left: 80;
      padding-right: 80;
      padding-bottom: 90;
    `,
  })};
`

S.Container = styled(Container)`
  ${breakpoints({
    xs: css`
      margin-bottom: 80;
      margin-top: 80;
    `,
    md: css`
      margin-bottom: 130;
      margin-top: 130;
    `,
  })}
`
S.ButtonLink = styled(ButtonLink)`
  justify-self: start;
  letter-spacing: 0;
`

S.Image = styled.div`
  position: absolute;

  ${breakpoints({
    xs: css`
      bottom: -30%;
      right: -20%;
      width: 80%;
    `,
    md: css`
      bottom: -45%;
      right: 0;
      width: 60%;
    `,
  })}
`
