import React from 'react'
import { isEmpty } from 'lodash'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { TitleSection } from '@atoms/Typography'
import { Container, Col, Row } from '@atoms/Grid'

import CardProject from '@molecules/CardProject'

const applyFilter = (projects = [], filterKey = '', filterValues = []) => {
  if (isEmpty(projects) || isEmpty(filterValues)) {
    return []
  }

  let filtered = []
  const test = filterValues.map((filter) => {
    filtered = projects.filter((project) =>
      (project.frontmatter[filterKey] || []).includes(filter)
    )
  })

  return filtered
}

const ProjectList = ({
  sectionTitle,
  data,
  filterKey,
  filterValues,
  ...props
}) => {
  const projects = !isEmpty(data)
    ? applyFilter(data, filterKey, filterValues)
    : []

  return (
    <aside>
      <Container mb={{ xs: 70, md: 65 }} {...props}>
        {sectionTitle && (
          <Row>
            <Col>
              <S.TitleSection as="h3">{sectionTitle}</S.TitleSection>
            </Col>
          </Row>
        )}
        <Row>
          {projects.map((item) => (
            <Col col={{ xs: 1, md: 1 / 2 }}>
              <CardProject
                {...item.frontmatter}
                key={item.frontmatter.slug}
                layout="card"
                coverImage={item.frontmatter.coverImage}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </aside>
  )
}

const S = {}

S.Content = styled.box`
  ${breakpoints({
    xs: css`
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: 1fr;
    `,
    md: css`
      grid-template-columns: 1fr 1fr;
      column-gap: 20;
    `,
    xl: css`
      justify-content: space-between;
      grid-template-columns: minmax(540px, 540px) minmax(540px, 540px);
    `,
  })}
`

S.TitleSection = styled(TitleSection)`
  letter-spacing: -0.02em;
  ${breakpoints({
    md: css`
      line-height: 1.3;
      margin-bottom: 55;
    `,
  })}
`

export default ProjectList
