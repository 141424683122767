import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { breakpoints, css } from '@xstyled/styled-components'

import FadeInView from '@atoms/FadeInView'
import { Row, Col } from '@atoms/Grid'
import { TitleContent } from '@atoms/Typography'
import HTMLContent from '@atoms/HTMLContent'
import transformMDtoHTML from '@utils/transformMDtoHTML'
import { th } from '@xstyled/system'

const ImageContent = ({ inverse, noImage, data, ...props }) => {
  const { title, paragraphs, image, list, status } = data
  const __html = transformMDtoHTML(paragraphs)

  return (
    <S.Wrapper
      flexDirection={{ md: inverse ? 'row-reverse' : 'row' }}
      {...props}
    >
      <Col
        col={{ xs: 1, md: 1 / 2, lg: 5 / 12 }}
        mr={{ lg: inverse ? 0 : `${(1 / 12) * 100}%` }}
        ml={{ lg: !inverse ? 0 : `${(1 / 12) * 100}%` }}
      >
        {!noImage && (
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={title}
            layout="fullWidth"
            display={noImage ? 'none' : 'block'}
          />
        )}
      </Col>
      <Col
        col={{ xs: 1, md: 5 / 12 }}
        mr={{ md: !inverse ? 0 : `${(1 / 12) * 100}%` }}
        ml={{ md: inverse ? 0 : `${(1 / 12) * 100}%` }}
      >
        <S.Content>
          <FadeInView>
            <S.TitleContent>{title}</S.TitleContent>
          </FadeInView>
          {list && list.length > 0 && (
            <FadeInView>
              <HTMLContent>
                <ul>
                  {list.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </HTMLContent>
            </FadeInView>
          )}
          <FadeInView>
            <HTMLContent
              dangerouslySetInnerHTML={{
                __html,
              }}
            />
            {status && <S.Pill>{status}</S.Pill>}
          </FadeInView>
        </S.Content>
      </Col>
    </S.Wrapper>
  )
}

export default ImageContent

const S = {}

S.TitleContent = styled(TitleContent)`
  ${breakpoints({
    xs: css`
      font-weight: ${th('fontWeights.medium')};
      font-size: 22;
      line-height: 1.4;
      margin-top: 25;
      margin-bottom: 25;
      letter-spacing: -0.02em;
    `,
    md: css`
      font-size: 26;
      margin-top: 30;
      margin-bottom: 15;
    `,
    lg: css`
      font-size: 28;
      margin-top: 60;
      margin-bottom: 20;
    `,
    xl: css`
      font-size: 48;
      margin-top: 90;
      margin-bottom: 30;
    `,
  })}
`

S.Wrapper = styled(Row)`
  ${breakpoints({
    xs: css`
      /* display: flex;
      flex-direction: column; */
    `,
    md: css`
      justify-content: space-between;
      align-items: flex-start;
    `,
  })}
`

S.Content = styled.divBox`
  width: 100%;
  ${breakpoints({
    md: css`
      max-width: 486;
    `,
  })}
`

S.Pill = styled.div`
  display: inline-block;
  background: #fceae7;
  border-radius: 100px;
  padding: 10px 20px;
  font-size: 17px;
`
