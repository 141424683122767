import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import { Text } from '@atoms/Typography'
import arrowLeft from '@assets/svg/arrow-left.svg'

const ImageCard = ({ data, ...props }) => {
  const { title, paragraphs, image, linkUrl } = data
  return (
    <S.Wrapper {...props}>
      <S.Img image={image.childImageSharp.gatsbyImageData} alt={title} />
      <S.Card to={linkUrl} title={title}>
        <S.Title as="h3">{title}</S.Title>
        <S.Description>{paragraphs}</S.Description>
        <S.Arrow />
      </S.Card>
    </S.Wrapper>
  )
}

export default ImageCard

const S = {}

S.Wrapper = styled.box`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${th('colors.grey')};
  ${breakpoints({
    xs: css`
      border-radius: 5px;
    `,
    md: css`
      border-radius: 8px;
      border: none;
    `,
  })}
`

S.Img = styled(GatsbyImage)`
  display: block;
  ${breakpoints({
    xs: css`
      img {
        border-radius: 5px 5px 0 0;
      }
    `,
    md: css`
      height: 545;
      img {
        border-radius: 0;
        object-fit: cover;
      }
    `,
  })}
`

S.Card = styled(Link)`
  background-color: ${th('colors.light')};
  border-radius: 5px;
  ${breakpoints({
    xs: css`
      padding: 20;
    `,
    md: css`
      position: absolute;
      left: 50;
      bottom: 50;
      width: 500;
      padding: 60 50;
      border: 1px solid ${th('colors.grey')};
    `,
  })}
`

S.Title = styled(Text)`
  ${breakpoints({
    xs: css`
      font-size: 20;
      margin-bottom: 10;
      font-weight: ${th('fontWeights.medium')};
    `,
    md: css`
      font-size: 32;
      font-weight: ${th('fontWeights.bold')};
    `,
  })}
`

S.Description = styled(Text)`
  ${breakpoints({
    xs: css`
      font-size: 15;
      margin-bottom: 30;
    `,
    md: css`
      font-size: 19;
      margin-bottom: 50;
    `,
  })}
`

S.Arrow = styled(arrowLeft)`
  width: 27;
  height: 16;
  transform: rotate(180deg);
  path {
    color: currentColor;
  }
`
