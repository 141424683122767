import React from 'react'
import { Text, TitleSection } from '@atoms/Typography'
import { Container } from '@atoms/Grid'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import { GatsbyImage } from 'gatsby-plugin-image'

import idgen from '@utils/idgen'

const LogosList = ({ data }) => {
  const { title, items } = data
  return (
    <S.Wrapper>
      <Container>
        <TitleSection>{title}</TitleSection>
        <S.WrapperLogos>
          {items.map((item) => (
            <S.TechContainer key={idgen()}>
              <S.LogoContainer>
                <S.LogoImage
                  image={item.logo.childImageSharp.gatsbyImageData}
                  alt={item.title}
                />
              </S.LogoContainer>
              <S.LogoTitle>{item.title}</S.LogoTitle>
            </S.TechContainer>
          ))}
        </S.WrapperLogos>
      </Container>
    </S.Wrapper>
  )
}

export default LogosList

const S = {}

S.Wrapper = styled.box`
  background-color: ${th('colors.pink')};
  ${breakpoints({
    xs: css`
      padding: 50 0;
      margin-bottom: 50;
    `,
    md: css`
      padding: 130 0;
      margin-bottom: 0;
    `,
  })}
`

S.WrapperLogos = styled.box`
  display: grid;
  ${breakpoints({
    xs: css`
      grid-template-columns: repeat(3, 1fr);
      column-gap: 20;
      row-gap: 40;
    `,
    sm: css`
      grid-template-columns: repeat(4, 1fr);
    `,
    md: css`
      grid-template-columns: repeat(6, 1fr);
      column-gap: 0;
      row-gap: 80;
    `,
    lg: css`
      grid-template-columns: repeat(8, 1fr);
    `,
  })}
`

S.TechContainer = styled.box`
  display: flex;
  flex-direction: column;
  align-items: center;
`

S.LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${breakpoints({
    xs: css`
      height: 70;
      max-width: 50;
    `,
    md: css`
      height: 72;
      max-width: 55;
    `,
  })}
`

S.LogoImage = styled(GatsbyImage)`
  width: 100%;
`

S.LogoTitle = styled(Text)`
  text-align: center;
  font-weight: ${th('fontWeights.medium')};
  ${breakpoints({
    xs: css`
      font-size: 13;
    `,
    md: css`
      font-size: 16;
    `,
  })}
`
