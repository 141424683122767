import React from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'

import ImageSection from '@atoms/ImageSection'

const CoverImage = ({ data, full }) => {
  return (
    <S.CoverImage>
      <ImageSection
        full={full}
        alt=""
        image={data.image.childImageSharp.gatsbyImageData}
      />
    </S.CoverImage>
  )
}

const S = {}

S.CoverImage = styled.div`
  ${breakpoints({
    xs: css`
      margin-bottom: 80;
    `,
    lg: css`
      margin-bottom: 180;
    `,
  })}
`

export default CoverImage
