import React from 'react'
import { TitleSection } from '@atoms/Typography'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { Col, Container, Row } from '@atoms/Grid'
import ListItem from '@atoms/ListItem'

const ListServices = ({ data }) => {
  const { title, list } = data
  return (
    <S.Section>
      <Row>
        <Col col={{ xs: 1, md: 1 / 2, lg: 6 / 12 }}>
          <S.TitleSection>{title}</S.TitleSection>
        </Col>

        <Col col={{ xs: 1, md: 1 / 2, lg: 5 / 12 }}>
          {list.map((item) => (
            <S.ListItem key={item}>{item}</S.ListItem>
          ))}
        </Col>
      </Row>
    </S.Section>
  )
}

export default ListServices

const S = {}

S.Section = styled(Container)`
  ${breakpoints({
    xs: css`
      margin-bottom: 100;
    `,
    md: css`
      margin-bottom: 180;
    `,
  })}
`

S.TitleSection = styled(TitleSection)`
  letter-spacing: -0.02em;
  ${breakpoints({
    md: css`
      line-height: 1.3;
    `,
  })}
`

S.ListItem = styled(ListItem)`
  line-height: 1.4;
  ${breakpoints({
    md: css`
      font-size: 20;
      padding-bottom: 17;
    `,
  })}
`
