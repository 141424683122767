import React from 'react'

import styled, { css, x } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'

import { H1 } from '@atoms/Typography'
import BackButton from '@atoms/BackButton'
import { Container } from '@atoms/Grid'

const Hero = ({ back, invertColor, data, background, ...props }) => {
  const { title, paragraphs, linkButton, linkUrl } = data

  return (
    <S.Wrapper invertColor={invertColor} background={background} {...props}>
      <S.Container>
        {back && (
          <BackButton
            to={`${linkUrl}#services`}
            variant={invertColor ? 'dark' : 'light'}
            linkButton={linkButton}
          />
        )}
        <div>
          <S.H1 display="inline-block" as="h1">
            {title}
          </S.H1>
          <S.H1Normal as="h2" display="inline-block">
            {paragraphs}
          </S.H1Normal>
        </div>
      </S.Container>
    </S.Wrapper>
  )
}

export default Hero

const S = {}

S.Wrapper = styled.section`
  ${({ invertColor }) =>
    invertColor &&
    css`
      color: ${th('colors.light')};
    `}
  ${({ background }) =>
    background &&
    css`
      background-color: ${th(`colors.${background}`)};
    `}
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    md: css`
      flex-direction: row;
      align-items: flex-start;
    `,
  })}
`

S.Container = styled(Container)`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      margin-top: 220;
      margin-bottom: 90;
    `,
    md: css`
      margin-top: 292;
      margin-bottom: 180;
    `,
    lg: css`
      flex-direction: row;
      align-items: flex-start;
    `,
  })}
`

S.H1Normal = styled(H1)`
  line-height: 1.5;
  letter-spacing: -0.02em;
  font-weight: ${th('fontWeights.normal')};
  ${breakpoints({
    xs: css`
      font-size: 24;
    `,
    md: css`
      font-size: 48;
      max-width: 996;
    `,
  })}
`

S.H1 = styled(H1)`
  line-height: 1.5;
  letter-spacing: -0.02em;
  font-weight: ${th('fontWeights.medium')};
  ${breakpoints({
    xs: css`
      font-size: 24;
    `,
    md: css`
      font-size: 48;
      max-width: 996;
    `,
  })}
`
