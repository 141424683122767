import React from 'react'
import { graphql } from 'gatsby'
import styled from '@xstyled/styled-components'
import colorByService from '@utils/colorByService'
import idgen from '@utils/idgen'
import { Container } from '@atoms/Grid'
import ImageCard from '@molecules/ImageCard'
import LogosList from '@organisms/ServiceSingle/LogosList'
import Layout from '@organisms/Layout'
import Hero from '@organisms/ServiceSingle/Hero'
import ListServices from '@organisms/ServiceSingle/ListServices'
import SectionTitle from '@organisms/ServiceSingle/SectionTitle'
import CopyImage from '@organisms/ServiceSingle/CopyImage'
import Cta from '@molecules/Cta'
import CoverImage from '@organisms/ServiceSingle/CoverImage'

import ProjectListGrid from '@molecules/ProjectListGrid'

const ServiceSingle = ({ data, path }) => {
  const { blocks, meta, title, type, serviceColor, keywords } =
    data.services.nodes[0].frontmatter

  const isDark = serviceColor === 'dark'
  const isYellow = serviceColor === 'yellow'
  const projects = data.projects

  return (
    <Layout
      hamburgerMenu
      title={meta.title}
      description={meta.description}
      buttonColor={isYellow ? 'dark' : 'yellow'}
      invertColor={isDark}
      customBackgroundColor="white"
      service
      serviceSchemaData={{
        title: title,
        description: meta.description,
        services: keywords,
      }}
    >
      <S.Main itemScope itemType="https://schema.org/Service">
        <meta itemProp="serviceType" content={title} />
        {blocks && blocks.length > 0
          ? blocks.map((item) => {
              switch (item.type) {
                case 'hero':
                  return (
                    <Hero
                      key={idgen()}
                      data={item}
                      background={serviceColor}
                      invertColor={isDark}
                      back
                    />
                  )
                case 'image':
                  return <CoverImage full key={idgen()} data={item} />
                case 'services':
                  return <ListServices data={item} key={idgen()} />
                case 'copy':
                  return <SectionTitle title={item.title} key={idgen()} />
                case 'copyImage':
                  return (
                    <CopyImage
                      data={{ ...item, image: item.sideImage }}
                      key={idgen()}
                    />
                  )
                case 'imageCard':
                  return (
                    <Container mb={{ xs: 50, md: 130 }} key={idgen()}>
                      <ImageCard data={item} />
                    </Container>
                  )
                case 'logosList':
                  return <LogosList data={item} key={idgen()} />
                case 'cta':
                  return <Cta data={item} key={idgen()} />
                default:
                  return null
              }
            })
          : null}

        <ProjectListGrid
          sectionTitle={title}
          data={projects.nodes}
          filterKey="tags"
          filterValues={[type]}
        />
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox``

export const pageQuery = graphql`
  query ($slug: String!) {
    services: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $slug } } }
    ) {
      nodes {
        frontmatter {
          projectsFilter
          type
          serviceColor
          keywords
          ...RepeaterFragment
        }

        html
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { collection: { eq: "project" } } }
    ) {
      nodes {
        frontmatter {
          slug
          title
          excerpt
          tags
          coverProjectImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          cardImage: coverImage {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.4210526316
                transformOptions: { cropFocus: CENTER }
                layout: CONSTRAINED
              )
            }
          }
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default ServiceSingle
