import React from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { Container } from '@atoms/Grid'
import { TitleSection } from '@atoms/Typography'

const SectionTitle = ({ title }) => {
  return (
    <S.Section>
      <S.TitleSection>{title}</S.TitleSection>
    </S.Section>
  )
}

export default SectionTitle

const S = {}

S.Section = styled(Container)`
  ${breakpoints({
    xs: css`
      margin-bottom: 30;
    `,
    md: css`
      margin-bottom: 65;
    `,
  })}
`

S.TitleSection = styled(TitleSection)`
  letter-spacing: -0.02em;
  ${breakpoints({
    md: css`
      line-height: 1.3;
      margin-bottom: 96;
    `,
  })}
`
