import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { breakpoints, css, th } from '@xstyled/styled-components'

import FadeInView from '@atoms/FadeInView'
import { Text } from '@atoms/Typography'

const CardProject = ({ title, slug, coverImage, excerpt, layout }) => {
  return (
    <S.Wrapper layout={layout}>
      <FadeInView>
        <Link to={`/work/${slug}/`} title={title}>
          <S.ImageContainer layout={layout}>
            <GatsbyImage
              image={coverImage.childImageSharp.gatsbyImageData}
              alt={title}
            />
          </S.ImageContainer>

          <Text
            as="h3"
            fontSize={{ xs: 17, md: 22, xl: 24 }}
            lineHeight={{ xs: '27px', md: '30px', xs: 1.6 }}
          >
            <S.TitleClient>{title}</S.TitleClient>
            {` - `}
            {excerpt}
          </Text>
        </Link>
      </FadeInView>
    </S.Wrapper>
  )
}

export default CardProject

const S = {}

S.TitleClient = styled.span`
  font-weight: ${th('fontWeights.medium')};
`

const shortLayout = {
  wrapper: css`
    ${breakpoints({
      md: css`
        width: calc(50% - 60px);
      `,
    })}
  `,
  image: css`
    &::after {
      ${breakpoints({
        xs: css`
          padding-bottom: 70%;
        `,
        md: css`
          padding-bottom: 100%;
        `,
      })}
    }
  `,
}

const tallLayout = {
  wrapper: css`
    ${breakpoints({
      md: css`
        width: calc(50% - 60px);
      `,
    })}
  `,
  image: css`
    &::after {
      ${breakpoints({
        xs: css`
          padding-bottom: 70%;
        `,
        md: css`
          padding-bottom: 120%;
        `,
      })}
    }
  `,
}

const card = {
  wrapper: css``,
}

const wideLayout = {
  wrapper: css`
    ${breakpoints({
      md: css`
        width: 70%;
        margin-left: 30%;
      `,
    })}
  `,
  image: css`
    &::after {
      ${breakpoints({
        xs: css`
          padding-bottom: 70%;
        `,
        md: css`
          padding-bottom: 56.25%;
        `,
      })}
    }
  `,
}

S.Wrapper = styled.box`
  width: 100%;

  ${({ layout }) => {
    if (layout === 'short') return shortLayout.wrapper
    if (layout === 'tall') return tallLayout.wrapper
    if (layout === 'card') return card.wrapper
    return wideLayout.wrapper
  }}

  ${breakpoints({
    xs: css`
      margin-bottom: 50;
    `,
    md: css`
      margin-bottom: 120;
      &:nth-of-type(4) {
        margin-top: 260;
      }
    `,
  })}
`

S.ImageContainer = styled.box`
  position: relative;
  width: 100%;

  ${({ layout }) => {
    if (layout === 'short') return shortLayout.image
    if (layout === 'tall') return tallLayout.image
    if (layout === 'card') return card.image
    return wideLayout.image
  }}

  ${breakpoints({
    xs: css`
      margin-bottom: 20;
    `,
    md: css`
      margin-bottom: 40;
    `,
  })}
`
